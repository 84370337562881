.page-header {
  width: 100vw;
  height: 46px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  color: var(--adm-color-primary);
  border-bottom: 0.5px solid #ccc;
  background-color: #ffffff;
  position: relative;
  .arrow {
    margin-right: auto;
    position: absolute;
    left: 10px;
  }
  .header-name {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
