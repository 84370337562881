.search {
  width: 100vw;
  padding: 6px 12px;
  box-sizing: border-box;
  .search-container {
    width: 100%;
    padding: 4px 0;
    .adm-search-bar {
      width: 100%;
    }
  }
}
.list-container {
  flex: 1;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
}
.table-container {
  flex: 1 0 auto;
  width: 100%;
  height: 0;
  padding: 0 8px 8px;
  box-sizing: border-box;
  overflow: hidden;
}
