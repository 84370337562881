.adm-form {
  --border-top: none;
}
.adm-form-item.adm-form-item-horizontal .adm-list-item-content-prefix {
  width: 65px;
  padding: 0;
}
.adm-list-item-content-main {
  padding: 0;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
}
.adm-list-item-content {
  border: none;
  padding: 3px 0;
  margin: 6px 0;
}
.adm-form-item-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.adm-form-item-label .adm-form-item-required-asterisk {
  position: absolute;
  left: -0.6em;
  top: 0.3em;
  font-family: SimSun, sans-serif;
  color: var(--adm-color-danger);
  -webkit-user-select: none;
  user-select: none;
}
.adm-list-body {
  font-size: 14px;
}
.adm-form-item-child {
  width: 100%;
}
.adm-input {
  // height: 20px;
  font-size: 13px;
  .adm-input-element {
    padding: 0 8px;
    font-size: 14px;
    display: block;
    border-radius: 2px;
    min-height: 32px;
  }
}
.adm-list-default .adm-list-body {
  border-bottom: none;
}
.adm-form-item-child-inner {
  height: 30px;
}
