.table {
  width: 100%;
  box-sizing: border-box;
  border-spacing: 0;
  border: 0.5px solid rgba(204, 204, 204, 0.3);
  border-collapse: collapse;
  table-layout: fixed;
  thead {
    height: 0;
    & > tr {
      height: 40px;
      font-size: 14px;
      color: #666666;
      line-height: 14px;
      font-weight: 400;
      text-align: center;
      &:nth-child(2n + 1) {
        background-color: #ffffff;
      }
    }
  }
  tbody {
    width: 100%;
    overflow-y: scroll;
    //表格行通用设置
    tr {
      height: 40px;
      font-size: 14px;
      color: #666666;
      line-height: 14px;
      font-weight: 400;
      text-align: center;

      &:nth-child(2n) {
        background-color: #ffffff;
      }
    }
  }

  //表头样式设置
  .head {
    font-size: 14px;
    color: #666666;
    line-height: 14px;
    font-weight: 600;
  }
}

table thead tr:nth-child(2n + 1) {
  background-color: #ffffff;
}

table tr:nth-child(2n) {
  background-color: #ffffff;
}

table .head {
  font-size: 14px;
  color: #666666;
  line-height: 14px;
  font-weight: 600;
}
