.adm-popup-body {
  height: 240px;
  .user-info {
    width: 100%;
    line-height: 40px;
    font-size: 18px;
    .title {
      font-weight: 600;
      margin-right: 10px;
    }
  }
}
