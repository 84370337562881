
img {
  width: 30px;
  height: 30px;
  margin-right: 4px;
}
ol {
  color: #292727;
  margin: 0;
  padding: 0;
  line-height: 16px;
  font-size: 13px;
  list-style: none;
  font-weight: 500;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
}
li {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 10px;
}
.mask-content {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .dialog-area {
    width: 86vw;
    height: 75vh;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    .main-title {
      color: #000000;
      text-align: center;
      font-size: 18px;
      margin: 0;
      width: 100%;
      height: 30px;
    }
    .notice-container {      
      color: #000000;
      flex: 1;
      height: 0px;
      overflow-y: scroll;
      .title-desc {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
          .item {
            color: #319FF8;
          }
          .warning {
            color: rgb(221, 75, 67);
            margin-bottom: 8px;
          }
      }
      .warning {
        color: rgb(221, 75, 67);
        font-weight: 600;
        margin-bottom: 6px;
      }
    }
    .agress {
      width: 100%;
    }
  }
}
.adm-checkbox-content {
  font-size: 14px;
}