.reservationForm {
  width: 100vw;
  box-sizing: border-box;
  padding: 14px 10px 0;
  .tips {
    margin-top: 14px;
    color: #e1251b;
    font-size: 14px;
    font-weight: 600;
  }
  .form-container-main {
    background-color: #ffffff;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 14px;
    padding: 6px;
    box-sizing: border-box;
    .formTitle {
      margin: 8px 0;
      overflow: hidden;
      font-size: 12px;
      line-height: 26px;
      font-weight: 500;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      .icon {
        width: 32px;
        height: 32px;
      }
      .name {
        width: 100px;
        height: 26px;
        background-color: var(--adm-color-primary);
        text-align: center;
        color: #ffffff;
        font-weight: 600;
        font-size: 14px;
        border-radius: 6px;
        margin-left: 6px;
      }
    }
  }
}
.action {
  margin-top: auto;
}
