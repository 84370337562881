.card-area {
  box-sizing: border-box;
  padding: 0 10px;
  margin-top: 10px;
  .adm-card {
    .adm-card-body {
      .info-one-line {
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
      }
      .info-details {
        font-size: 14px;
        line-height: 20px;
        color: var(--adm-color-primary);
      }
    }
  }
}
.title-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  .icon {
    width: 26px;
    height: 26px;
  }
  .name {
    width: 100px;
    height: 28px;
    background-color: var(--adm-color-primary);
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    border-radius: 6px;
    margin-left: 6px;
    line-height: 28px;
  }
}
