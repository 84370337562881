.page-container {
  width: 100vw;
  height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  font-family: PingFangSC-Medium, sans-serif;
  background-color: #ffffff;
  position: relative;
  .page-content {
    width: 100vw;
    height: 100vh;
    flex: 1 0 auto;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    background-color: rgba(204, 204, 204, 0.3);
  }
}
.action {
  padding: 0 10px 8px;
}
:root:root {
  --adm-color-primary: rgba(22, 119, 255, 1);
}
body {
  font-family: pingfangSC-Medium;
}
