.search {
  width: 100vw;
  padding: 6px 12px;
  box-sizing: border-box;
  .search-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
}
.table-header-container {
  padding: 0 8px 0;
  height: 40px;
  .table-header {
    width: 100%;
    box-sizing: border-box;
    border-spacing: 0;
    border: 0.5px solid rgba(204, 204, 204, 0.3);
    border-collapse: collapse;
    table-layout: fixed;
    & > thead {
      position: sticky;
      & > tr {
        height: 40px;
        font-size: 14px;
        color: #666666;
        line-height: 14px;
        font-weight: 400;
        text-align: center;
        &:nth-child(2n + 1) {
          background-color: #ffffff;
        }
      }
    }
  }
}
.page-content {
  height: 0;
  overflow: hidden;
}
.table-scroll-container {
  height: 0;
  flex: 1;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 8px 0;
  margin-bottom: 8px;
}
.adm-input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 16px 0 0;
}
.adm-input-element {
  min-height: 40px;
  border: 1px solid var(--adm-color-light);
  border-radius: 8px;
  padding: 8px;
}
